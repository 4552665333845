import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["loadConversationButton", "searchPatient", "smsOption", "emailOption", "sendMessageButton", "phoneInput", "emailInput", "subjectInput"];

  initialize() {
    this.debounceTimeout = null;
  }

  performSearch(e) {
    e.preventDefault();

    if (this.debounceTimeout) { clearTimeout(this.debounceTimeout); }

    this.debounceTimeout = setTimeout(() => {
      const searchQuery = this.searchPatientTarget.value;
      this.sendSearchRequest(searchQuery);
    }, 500);
  }

  sendSearchRequest(query) {
    fetch(`/patients?query=${query}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          document.querySelector('.chats').innerHTML = data.map(patient => {
            let date = new Date(patient.last_communicated_at).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });

            return `
              <div class="chat_list" id="patient_${patient.id}"
                data-controller='patient-conversation'
                data-patient-id="${patient.id}"
                data-target="patient-conversation.loadConversationButton"
                data-action="click->patient-conversation#loadConversation">
                <div class="chat_patient">
                  <div class="patient_name">
                    <h5>${patient.first_name + patient.last_name}</h5>
                    <h5> Last communication: ${date}</h5>
                  </div>
                </div>
              </div>
            `;
          }).join('');
        }
      })
      .catch(error => console.error('Error loading more posts:', error));
  }

  changeMessageOption(e) {
    e.preventDefault();

    const clickedTarget = e.target;
    if (clickedTarget == this.smsOptionTarget) {
      document.querySelector('.message_info').classList.remove('d-none');
      document.querySelector('.email_info').classList.add('d-none');
    } else if (clickedTarget == this.emailOptionTarget) {
      document.querySelector('.email_info').classList.remove('d-none');
      document.querySelector('.message_info').classList.add('d-none');
    }
  }

  sendConversationMsg(e) {
    e.preventDefault();

    const patientId = this.sendMessageButtonTarget.dataset['patientId']
    const message = document.querySelector('.message_input').value;

    let error_message = ""
    if (message == '') { error_message = "Message can't be blank" }

    const messageInfoDiv = document.querySelector('.message_info');
    const emailInfoDiv = document.querySelector('.email_info');
    const messagesDiv = document.querySelector('.messages');

    let body = {}
    if (messageInfoDiv.classList.contains('d-none')) {
      if (this.emailInputTarget.value == '' || this.subjectInputTarget.value == '' || message == '') { return alert(`Email, Subject and ${error_message}`) }

      body = {
        message_type: 'email',
        email: this.emailInputTarget.value,
        subject: this.subjectInputTarget.value,
        message: message,
      }
    } else if (emailInfoDiv.classList.contains('d-none')) {
      if (this.phoneInputTarget.value == '' || message == '') { return alert(`Phone and ${error_message}`) }

      body = {
        message_type: 'sms',
        phone: this.phoneInputTarget.value,
        message: message
      }
    }

    fetch(`/patients/${patientId}/send_conversation_message`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status == 'success') {
          const patient = document.querySelector('#patient_' + data.patient.id);
          patient.click();
        }

        alert(data.message);
      })
      .catch(error => console.error('Error sending message:', error));
  }

  loadConversation(e) {
    e.preventDefault();

    const patientId = this.loadConversationButtonTarget.dataset['patientId']
    $('.chat_list').removeClass('active_chat');
    $('#patient_' + patientId).addClass('active_chat');

    fetch(`/patients/${patientId}/conversation`, {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then((response) => response.text())
      .then((html) => {
        document.querySelector('.messages').innerHTML = html;
      })
      .catch(error => console.error('Error loading conversation:', error));
  }
}
