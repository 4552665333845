import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["loadMoreButton"];

  connect() {
    $(".def").get(0).scrollIntoView({behavior: 'smooth'});

  }
  loadMore(e) {
    e.preventDefault();

    const patientId = this.loadMoreButtonTarget.dataset['patientId']
    const nextPage = this.loadMoreButtonTarget.dataset['pagyNext']

    fetch(`/patients/${patientId}?page=${nextPage}&load_more_patient_logs=true`, {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.patient_logs.length > 0) {
          const patientLogContainer = document.getElementById("patient-logs-container");
          data.patient_logs.forEach((log) => {
            const accordionItem = this.createAccordionItem(log);
            patientLogContainer.appendChild(accordionItem);
          });

          if (data.next_page) {
            this.loadMoreButtonTarget.dataset['pagyNext'] = data.next_page;
          } else {
            this.loadMoreButtonTarget.style.display = 'none';
          }
        }
      })
      .catch(error => console.error('Error loading more posts:', error));
  }

  createAccordionItem(log) {
    const accordionItem = document.createElement('div');
    accordionItem.classList.add('accordion', 'accordion-flush');

    const accordionItemDiv = document.createElement('div');
    accordionItemDiv.classList.add('accordion-item');

    const accordionHeader = document.createElement('h2');
    accordionHeader.classList.add('accordion-header');

    const timelineContent = this.createTimelineContent(log);

    accordionHeader.appendChild(timelineContent);
    accordionItemDiv.appendChild(accordionHeader);
    accordionItem.appendChild(accordionItemDiv);

    return accordionItem;
  }

  createTimelineContent(log) {
    const timelineItem = document.createElement('div');
    timelineItem.classList.add('vertical-timeline-item', 'vertical-timeline-element');

    const timelineIcon = document.createElement('span');
    timelineIcon.classList.add('vertical-timeline-element-icon', 'bounce-in');
    const badgeSuccess = document.createElement('i');
    badgeSuccess.classList.add('badge', 'badge-dot', 'badge-dot-xl', 'badge-success');
    timelineIcon.appendChild(badgeSuccess);
    timelineItem.appendChild(timelineIcon);

    const timelineContent = document.createElement('div');
    timelineContent.classList.add('vertical-timeline-element-content', 'bounce-in');

    const accordionButton = this.createAccordionButton(log);
    const detailGrid = this.createDetailGrid(log);
    const dateGrid = this.createDateGrid(log);
    const collapseDiv = this.createCollapseDiv(log);

    timelineContent.append(accordionButton, detailGrid, dateGrid, collapseDiv);
    timelineItem.appendChild(timelineContent);
    return timelineItem;
  }

  createAccordionButton(log) {
    const button = document.createElement('button');
    button.classList.add('accordion-button', 'collapsed');
    button.setAttribute('type', 'button');
    button.setAttribute('data-bs-toggle', 'collapse');
    button.setAttribute('data-bs-target', `#collapse${log.id}`);
    button.setAttribute('aria-expanded', 'false');
    button.setAttribute('aria-controls', `collapse${log.id}`);

    const title = document.createElement('h4');
    title.classList.add('timeline-title');
    title.textContent = log.title;

    button.appendChild(title);
    return button;
  }

  createDetailGrid(log) {
    const detailGrid = document.createElement("p");
    const textNode = document.createTextNode(
      log.title + " "
    );
    detailGrid.appendChild(textNode);

    const spanTag = document.createElement("span");
    const anchorTag = document.createElement("a");

    anchorTag.href = "javascript:void(0);";
    anchorTag.textContent = log.attempt_date;

    spanTag.appendChild(anchorTag);
    detailGrid.appendChild(spanTag);
    return detailGrid;
  }

  createDateGrid(log) {
    const dateGrid = document.createElement('div');
    dateGrid.classList.add('vertical-timeline-element-date', 'd-grid', 'text-left');
    const date = new Date(log.attempt_date);

    const dateSpan = document.createElement('span');
    dateSpan.textContent = date.toLocaleDateString();

    const weekdaySpan = document.createElement('span');
    weekdaySpan.textContent = date.toLocaleString('en-us', { weekday: 'long' });

    const timeSpan = document.createElement('span');
    timeSpan.textContent = date.toLocaleString('en-us', { hour: '2-digit', minute: '2-digit', timeZoneName: 'short', timeZone: 'UTC', hour12: false });

    dateGrid.append(dateSpan, weekdaySpan, timeSpan);
    return dateGrid;
  }

  createCollapseDiv(log) {
    const collapseDiv = document.createElement('div');
    collapseDiv.setAttribute('id', `collapse${log.id}`);
    collapseDiv.classList.add('accordion-collapse', 'collapse');
    collapseDiv.setAttribute('data-bs-parent', '#accordionExample');

    const collapseBody = document.createElement('div');
    collapseBody.classList.add('accordion-body');
    collapseBody.innerHTML = log.email_body || log.sms_body || log.pr_message;

    collapseDiv.appendChild(collapseBody);
    return collapseDiv;
  }
}
